











































import './scss/PopupTaskEdit.scss';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { ProjectTasksActions, ProjectTasksMutations } from '@store/modules/project-tasks/Types';
import { formatDate } from '@utility/formatDate';
import { ITask } from "@store/modules/project-tasks/interfaces/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSProjectTasks = namespace('storeProjectTasks');

@Component({
    name: 'PopupTaskEdit',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
    }
})

export default class PopupTaskEdit extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @NSProjectTeam.Getter('daconMembers') daconMembers!: IMemberInfo[];

    @Prop({}) defaultTask!: any;
    @Prop({}) editedTasks!: ITask[];
    @Prop({}) tasksString!: string;

    @NSProject.Getter('projectId') projectId!: number;
    @NSProjectTasks.Action(ProjectTasksActions.A_EDIT_TASK)
    editTask!: (data) => Promise<void>

    @NSProjectTasks.Mutation(ProjectTasksMutations.M_SET_TASKS_AFTER_CHANGE)
    setTaskAfterChange!: (data) => void;

    realtimeValidation: boolean = false;
    btnDisabled: boolean = false;

    fields = {
        title: '',
        executor: null as { label: String, uuid: String } | null,
        deadline: '',
    };

    get multipleTasks() {
        return this.editedTasks.length > 1;
    }

    get membersAsOptions() {
        return this.daconMembers.map((member) => ({
            label: member.fullName,
            uuid: member.uuid,
        }));
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd' });
    }

    checkSendForm() {
        this.btnDisabled = true;
        this.sendForm();
    }

    sendForm() {
        this.editTask({
            appId: this.projectId,
            tasks: this.editedTasks.map(task => {
                const output: any = {
                    id: task.uuid,
                    name: this.multipleTasks ? task.title : this.fields.title,
                    userId: this.fields.executor?.uuid,
                }
                if (this.fields.deadline) {
                    output.date = this.fields.deadline.replace(/T\d{1,2}:\d{1,2}:\d{1,2}/g, '') + 'T00:00:00'
                }
                return output;
            })
        }).then(() => {
            this.closePopup();
            this.changeTaskInState();
            this.$emit('on-change-task')
        }).finally(() => {
            this.btnDisabled = false;
        })
    }

    changeTaskInState() {
        const editedTasksDictionary = {};
        let taskData: any = {};
        this.editedTasks.forEach(task => {
            taskData = JSON.parse(JSON.stringify(task));
            if (!this.multipleTasks && taskData.title) {
                taskData.title = this.fields.title;
            }
            if (this.fields.executor) {
                taskData.executor = this.fields.executor;
            }
            if (this.fields.deadline) {
                taskData.deadline = this.fields.deadline.replace(/T\d{1,2}:\d{1,2}:\d{1,2}/g, '') + 'T00:00:00';
            }
            editedTasksDictionary[task.uuid] = taskData;
        });
        this.setTaskAfterChange(editedTasksDictionary);
    }

    openPopup() {
        this.resetFields();
        if (!this.multipleTasks) {
            this.setFieldsValuesFromDefault();
        }
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    resetFields() {
        this.fields = {
            title: '',
            executor: null as { label: String, uuid: String } | null,
            deadline: '',
        };
    }

    setFieldsValuesFromDefault() {
        const task = this.editedTasks[0];
        if (task.title) {
            this.fields.title = task.title;
        }
        if (task.executor) {
            this.fields.executor = {
                label: task.executor.fullName,
                uuid: task.executor.uuid,
            };
        }
        if (task.deadline) {
            this.fields.deadline = task.deadline.toString()
                .replace(/T\d{1,2}:\d{1,2}:\d{1,2}/g, '')
                .replace('0001', '2000');
        }
    }
}
